<template>
	<b-card title="Points Transaction List">
		<b-row class="mb-3">

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="code_ref_id">Kode Transaksi :</label>
					<input id="code_ref_id" type="text" class="form-control" placeholder="Search: tansaction"
						v-model="filter.code_ref_id">
				</div>
			</b-col>

			<!-- <b-col cols="12" lg="4">
				<div class="form-group">
					<label for="email">Email:</label>
					<input 
						id="email"
						type="text" 
						class="form-control"
						placeholder="Search: email"
						v-model="filter.email" 
					>
				</div>
			</b-col> -->

			<!-- <b-col cols="12" lg="3">
				<div class="form-group">
					<label for="show">Jenis Feedback:</label>
					<v-select
						id="show" 
						v-model="filter.feedback_jenis_id" 
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="name"
						placeholder="Pilih Type"
						:options="list_feedback_type"
						:reduce="(list_feedback_type) => list_feedback_type.id"
					/>
				</div>
			</b-col> -->

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label>Tanggal :</label>
					<flat-pickr v-model="filter.date" class="form-control" placeholder="Search: Date" :config="{
						locale: {
							rangeSeparator: ' - ',
						},
						maxDate: new Date(),
					}" />
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="status">Status :</label>
					<select id="status" v-model="filter.status" class="form-control"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
						<option value="" disabled selected>Pilih Type</option>
						<option v-for="(item, index) in status" :key="index" :value="index">
							{{ item }}
						</option>
					</select>
				</div>
			</b-col>

			<b-col cols="12" class="mt-2">
				<button class="btn btn-outline-secondary mr-2" @click="clearFilter">
					Reset
				</button>
				<button
					class="btn btn-primary"
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					v-b-modal.form-modal-export-csv
					@click="getDataExport">
						Export
					</button>
			</b-col>

			<Export
				:is-loading="isLoadingExport"
				:result-export="resultExport"
				:export-now="exportNow"
			/>
		</b-row>

		<Table :result="result" :feedback="feedback" :is-loading="isLoading" :get-data="getData"
			:format-date="formatDate" :current-page="currentPage" />
	</b-card>
</template>

<script>
import Export from '@/components/ExportCsv.vue'
import Table from '@/components/points-transaction/table.vue'
import { successNotification, errorNotification } from '@/auth/utils'
import {
	BRow,
	BCol,
	BCard,
	BTable,
	BFormInput,
	BButton,
	BSpinner,
	VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
export default {
	components: {
		Export,
		Table,
		BCard,
		BTable,
		BCol,
		BRow,
		BButton,
		BSpinner,
		BFormInput,
		vSelect,
		flatPickr,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			api: {
				feedback_list: '/admin/rewards-points/transaction',
				transaction_points_list: '/admin/export/points-activity/list',
				transaction_points_export: '/admin/export/points-activity/export',
				status_select: '/admin/rewards-points/transaction/status',
			},
			currentPage: 1,
			isLoading: false,
			result: [],
			feedback: {},
			list_feedback_type: [],
			link: '',
			isLoadingExport: false,
			resultExport: [],
			status: [],
			filter: {
				code_ref_id: '',
				date: '',
				status: '',
			},
		}
	},
	watch: {
		filter: {
			handler: _.debounce(async function () {
				await this.getData();
			}, 300),
			deep: true,
		},
	},
	setup(props) {
		return {
			successNotification,
			errorNotification,
		}
	},
	async created() {
		await this.getData()
		await this.getDataStatus()
	},
	methods: {
		getStatusLabel(value) {
			const statusLabels = {
				0: 'proses',
				1: 'berhasil',
				2: 'gagal'
			};
			return statusLabels[value];
		},
		async getDataStatus() {
			await this.$http.get(this.api.status_select)
				.then(response => {
					this.status = response.data.data
				}).catch(error => {
					return errorNotification(this, 'error get status :', error)
				})
		},
		async created() {
			await this.getData();
		},
		async getDataExport() {
			this.isLoadingExport = true
			await this.$http.get(this.api.transaction_points_list
			).then(response => {
				this.resultExport = response.data.data
				this.isLoadingExport = false
			}).catch(error => {
				return errorNotification(this, 'error send export request :', error)
			})
		},
		clearFilter() {
			this.filter = {
				code_ref_id: '',
				date: '',
				status: '',
			}
		},
		exportNow() {
				// this.isLoadingExport = true
				const exportParam = this.filter
				exportParam.per_page = null
				this.$swal({
							title: 'Sukses',
							icon: 'success',
							text: 'Mengexport data di background, mohon tunggu beberapa saat.',
							type: 'warning',
							customClass: {
								confirmButton: 'btn btn-primary',
							},
							buttonsStyling: false,
						}) 
				this.$bvModal.hide('form-modal-export-csv')
				this.$http.post(this.api.transaction_points_export, exportParam).then(response => {
					if(response) {
						// this.$swal({
						// 	title: 'Sukses',
						// 	icon: 'success',
						// 	text: 'Mengexport data di background, mohon tunggu beberapa saat.',
						// 	type: 'warning',
						// 	customClass: {
						// 		confirmButton: 'btn btn-primary',
						// 	},
						// 	buttonsStyling: false,
						// }) 
						console.log("success")
						// this.isLoadingExport = false
					}
				}).catch(error => {
					if (error.response.data.meta.validations) {
						this.validations = error.response.data.meta.validatiosns
					}
					return errorNotification(this, 'error Export', "Terjadi Kesalah Saat Export")	
				})
		},
		async getData(page) {
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
			console.log(queryParams)
			queryParams.page = page
			await this.$http.get(this.api.feedback_list, {
				params: queryParams,
			}).then(response => {
				console.log()
				this.result = response.data.data
				this.feedback = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			}).catch(error => {
				const result = error.response.data.meta.validations
				for (const x in result) {
					errorNotification(this, `error ${x}`, result[x][0])
				}
			})
		},
		formatDate(dateStr) {
			const date = new Date(dateStr);
			const options = { day: 'numeric', month: 'long', year: 'numeric' };
			return date.toLocaleDateString('id-ID', options);
		},
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>